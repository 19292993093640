import ModelBase from "@/apps/core/models/modelBase.js";

const modelName = "jadwal";
const modelPath = `/stase/jadwal-departemen/`;

class Jadwal extends ModelBase {
  constructor() {
    let model = {
        id: null,
        url: "",
        catatan: "",
    };
    let requiredFields = ['url'];
    super(modelName, model, requiredFields, ["catatan"], modelPath);
  }
}

export default Jadwal;